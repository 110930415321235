<template>
    
    <div class="home-container1">
      <div id="resources" class="home-hero">
        <div class="home-content-container1">
          <div class="home-hero-text">
           <h1></h1>
            <span class="home-text10 Section-Text">
                Welcome to RacketNation.ph, the premier online hub for racket sports enthusiasts in the Philippines. Whether you're a beginner or a pro, we provide everything you need to elevate your game—covering tennis, pickleball, padel, badminton, and more.
            </span>
            <span class="home-text10 Section-Text">
                Our platform connects players, coaches, and fans through expert equipment reviews, training programs, wellness insights, and a comprehensive player and coach directory. We are committed to supporting the country’s top junior players and elite coaches by offering exposure, sponsorship opportunities, and access to world-class training.
            </span>
            <span class="home-text10 Section-Text">
                Our mission is to be the go-to resource for racket sports in the Philippines, fostering a thriving, inclusive community. Join us in pushing the sport forward, supporting local talent, and shaping its future on a global scale. Together, we raise the game.
            </span>
          </div>
        </div>


        <div class="home-heading-container2">
          <h1 class="home-text18 Section-Heading">Who we are</h1>
          <span class="home-text19 Section-Text">
          
          </span>
        </div>
        <div class="home-cards-container2">
          <div class="home-service-card1">
            <h3 class="home-text20 Card-Heading">Mission Statement</h3>
            <span class="home-text21 Card-Text">
              RacketNation.ph is dedicated to being the ultimate hub for all racket sports enthusiasts in the Philippines. We aim to provide a comprehensive platform that connects players, coaches, and fans by offering top-tier resources, including equipment reviews, training programs, player and coach directories, wellness insights, rankings, and an integrated marketplace. Additionally, we are committed to supporting and empowering the country’s top junior players and elite-level coaches by providing exposure, sponsorship opportunities, and access to world-class training resources. Our goal is to cultivate a thriving racket sports community, fostering growth, inclusivity, and excellence across all levels of play.
            </span>
         
          </div>
          <div class="home-service-card2">
            <h3 class="home-text22 Card-Heading">Vision Statement</h3>
            <span class="home-text23 Card-Text">
              To be the leading digital ecosystem for racket sports in the Philippines, empowering athletes and enthusiasts with unparalleled access to information, innovation, and opportunities. RacketNation.ph envisions a future where every player, from grassroots to elite, can engage, learn, and elevate their game through a dynamic, well-connected, and resource-rich platform. By championing top junior talents and elite coaches, we strive to raise the level of racket sports in the Philippines to global standards.
             
            </span>
          </div>
         
        </div>
      </div>


       



      <!-- <div class="home-team">
        <div class="home-heading-container3">
          <h1 class="home-text26 Section-Heading">Meet the Team</h1>
          <span class="home-text27 Section-Text">
           
          </span>
        </div>
        <div class="home-cards-container3">
          <div class="home-team-card1">
            <div class="home-avatar-container1">
              <svg viewBox="0 0 1024 1024" class="home-icon32">
                <path
                  d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
                ></path>
              </svg>
            </div>
            <span class="home-name1 Card-Heading">John</span>
            <span class="home-position1 Card-Text">Position</span>
          </div>
          <div class="home-team-card2">
            <div class="home-avatar-container2">
              <svg viewBox="0 0 1024 1024" class="home-icon34">
                <path
                  d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
                ></path>
              </svg>
            </div>
            <span class="home-name2 Card-Heading">Megan</span>
            <span class="home-position2 Card-Text">Position</span>
          </div>
          <div class="home-team-card3">
            <div class="home-avatar-container3">
              <svg viewBox="0 0 1024 1024" class="home-icon36">
                <path
                  d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
                ></path>
              </svg>
            </div>
            <span class="home-name3 Card-Heading">Alex</span>
            <span class="home-position3 Card-Text">Position</span>
          </div>
          <div class="home-team-card4">
            <div class="home-avatar-container4">
              <svg viewBox="0 0 1024 1024" class="home-icon38">
                <path
                  d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
                ></path>
              </svg>
            </div>
            <span class="home-name4 Card-Heading">Sabrina</span>
            <span class="home-position4 Card-Text">Position</span>
          </div>
          <div class="home-team-card5">
            <div class="home-avatar-container5">
              <svg viewBox="0 0 1024 1024" class="home-icon40">
                <path
                  d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
                ></path>
              </svg>
            </div>
            <span class="home-name5 Card-Heading">Ben</span>
            <span class="home-position5 Card-Text">Position</span>
          </div>
        </div>
      </div> -->



    
      <!-- <div id="ourstory" class="home-our-story">
        <div class="home-heading-container4">
          <h1 class="home-text28 Section-Heading">Our story</h1>
          <span class="home-text29 Section-Text">
            Lorme ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
            odio. Praesent libero.
          </span>
        </div>
        <div class="home-cards-container4">
          <div class="home-left-section">
            <div class="home-video-container">
              <video class="home-video"></video>
              <div class="home-play-container">
                <svg viewBox="0 0 1024 1024" class="home-icon42">
                  <path
                    d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM426 704v-384l256 192z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="home-content-container2">
              <span class="home-heading17 Card-Heading">
                This text is the name of the article. Lorem ipsum dolor sit metsed
                do eiusm od tempor.
              </span>
              <span class="home-text30 Card-Text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
                odio. Praesent libero. Sed cursus ante dapibus diam.c Sed nisi.
                Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.
                Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris
                massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos.
              </span>
              <span class="home-text31 Card-Text">
                Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum
                lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos.
              </span>
              <span class="Anchor">Read more</span>
            </div>
            <div class="home-info-container1">
              <span class="Card-Text">12 Apr 2021</span>
              <div class="home-stats-container1">
                <div class="home-messages-container1">
                  <svg viewBox="0 0 1024 1024" class="home-icon44">
                    <path
                      d="M938.667 490.539v-21.205c0-0.725-0.043-1.621-0.085-2.475-5.803-99.755-47.488-190.336-112.768-258.176-68.352-71.125-162.731-117.419-268.843-123.264-0.64-0.043-1.493-0.085-2.304-0.085h-20.864c-59.947-0.683-122.965 13.227-181.931 43.008-52.181 26.539-97.749 63.531-133.931 108.203-56.405 69.675-89.899 158.037-89.941 253.653-0.597 54.4 10.795 111.36 35.157 165.419l-75.605 226.859c-2.816 8.363-3.072 17.835 0 26.965 7.467 22.357 31.616 34.432 53.973 26.965l226.731-75.563c49.493 22.485 105.984 35.243 165.376 35.115 58.539-0.384 115.84-13.141 168.149-36.949 81.579-37.163 151.040-101.248 193.707-186.667 27.477-53.291 43.307-115.84 43.136-181.803zM853.333 490.795c0.128 52.267-12.459 101.333-33.664 142.464-34.176 68.352-88.832 118.784-153.259 148.139-41.387 18.859-86.869 28.971-133.376 29.312-52.096 0.128-101.163-12.459-142.293-33.664-10.624-5.504-22.528-6.059-33.067-2.56l-162.261 54.101 54.101-162.261c3.755-11.221 2.56-22.912-2.389-32.725-23.552-46.72-34.304-96.213-33.792-142.464 0.043-76.331 26.411-145.877 70.912-200.917 28.629-35.328 64.768-64.725 106.283-85.76 46.592-23.552 96.085-34.304 142.336-33.792h19.456c83.712 4.565 158.037 41.003 212.011 97.109 51.285 53.376 84.139 124.416 89.003 202.837z"
                    ></path>
                  </svg>
                  <span class="Card-Text">123</span>
                </div>
                <div class="home-views-container1">
                  <svg viewBox="0 0 1024 1024" class="home-icon46">
                    <path
                      d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"
                    ></path>
                  </svg>
                  <span class="Card-Text">4567</span>
                </div>
              </div>
            </div>
          </div>
          <div class="home-right-section">
            <div class="home-card1">
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                class="home-image3"
              />
              <div class="home-content-container3">
                <span class="home-text34 SmallCard-Heading">
                  This text is the name of the article. Lorem ipsum dolor sit metsed
                  ...
                </span>
                <span class="Anchor">Read more</span>
              </div>
              <div class="home-info-container2">
                <span class="Card-Text">12 Apr 2021</span>
                <div class="home-stats-container2">
                  <div class="home-messages-container2">
                    <svg viewBox="0 0 1024 1024" class="home-icon48">
                      <path
                        d="M938.667 490.539v-21.205c0-0.725-0.043-1.621-0.085-2.475-5.803-99.755-47.488-190.336-112.768-258.176-68.352-71.125-162.731-117.419-268.843-123.264-0.64-0.043-1.493-0.085-2.304-0.085h-20.864c-59.947-0.683-122.965 13.227-181.931 43.008-52.181 26.539-97.749 63.531-133.931 108.203-56.405 69.675-89.899 158.037-89.941 253.653-0.597 54.4 10.795 111.36 35.157 165.419l-75.605 226.859c-2.816 8.363-3.072 17.835 0 26.965 7.467 22.357 31.616 34.432 53.973 26.965l226.731-75.563c49.493 22.485 105.984 35.243 165.376 35.115 58.539-0.384 115.84-13.141 168.149-36.949 81.579-37.163 151.040-101.248 193.707-186.667 27.477-53.291 43.307-115.84 43.136-181.803zM853.333 490.795c0.128 52.267-12.459 101.333-33.664 142.464-34.176 68.352-88.832 118.784-153.259 148.139-41.387 18.859-86.869 28.971-133.376 29.312-52.096 0.128-101.163-12.459-142.293-33.664-10.624-5.504-22.528-6.059-33.067-2.56l-162.261 54.101 54.101-162.261c3.755-11.221 2.56-22.912-2.389-32.725-23.552-46.72-34.304-96.213-33.792-142.464 0.043-76.331 26.411-145.877 70.912-200.917 28.629-35.328 64.768-64.725 106.283-85.76 46.592-23.552 96.085-34.304 142.336-33.792h19.456c83.712 4.565 158.037 41.003 212.011 97.109 51.285 53.376 84.139 124.416 89.003 202.837z"
                      ></path>
                    </svg>
                    <span class="Card-Text">123</span>
                  </div>
                  <div class="home-views-container2">
                    <svg viewBox="0 0 1024 1024" class="home-icon50">
                      <path
                        d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"
                      ></path>
                    </svg>
                    <span class="Card-Text">4567</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="home-card2">
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                class="home-image4"
              />
              <div class="home-content-container4">
                <span class="home-text37 SmallCard-Heading">
                  This text is the name of the article. Lorem ipsum dolor sit metsed
                  ...
                </span>
                <span class="Anchor">Read more</span>
              </div>
              <div class="home-info-container3">
                <span class="Card-Text">12 Apr 2021</span>
                <div class="home-stats-container3">
                  <div class="home-messages-container3">
                    <svg viewBox="0 0 1024 1024" class="home-icon52">
                      <path
                        d="M938.667 490.539v-21.205c0-0.725-0.043-1.621-0.085-2.475-5.803-99.755-47.488-190.336-112.768-258.176-68.352-71.125-162.731-117.419-268.843-123.264-0.64-0.043-1.493-0.085-2.304-0.085h-20.864c-59.947-0.683-122.965 13.227-181.931 43.008-52.181 26.539-97.749 63.531-133.931 108.203-56.405 69.675-89.899 158.037-89.941 253.653-0.597 54.4 10.795 111.36 35.157 165.419l-75.605 226.859c-2.816 8.363-3.072 17.835 0 26.965 7.467 22.357 31.616 34.432 53.973 26.965l226.731-75.563c49.493 22.485 105.984 35.243 165.376 35.115 58.539-0.384 115.84-13.141 168.149-36.949 81.579-37.163 151.040-101.248 193.707-186.667 27.477-53.291 43.307-115.84 43.136-181.803zM853.333 490.795c0.128 52.267-12.459 101.333-33.664 142.464-34.176 68.352-88.832 118.784-153.259 148.139-41.387 18.859-86.869 28.971-133.376 29.312-52.096 0.128-101.163-12.459-142.293-33.664-10.624-5.504-22.528-6.059-33.067-2.56l-162.261 54.101 54.101-162.261c3.755-11.221 2.56-22.912-2.389-32.725-23.552-46.72-34.304-96.213-33.792-142.464 0.043-76.331 26.411-145.877 70.912-200.917 28.629-35.328 64.768-64.725 106.283-85.76 46.592-23.552 96.085-34.304 142.336-33.792h19.456c83.712 4.565 158.037 41.003 212.011 97.109 51.285 53.376 84.139 124.416 89.003 202.837z"
                      ></path>
                    </svg>
                    <span class="Card-Text">123</span>
                  </div>
                  <div class="home-views-container3">
                    <svg viewBox="0 0 1024 1024" class="home-icon54">
                      <path
                        d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"
                      ></path>
                    </svg>
                    <span class="Card-Text">4567</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="home-card3">
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                class="home-image5"
              />
              <div class="home-content-container5">
                <span class="home-text40 SmallCard-Heading">
                  This text is the name of the article. Lorem ipsum dolor sit metsed
                  ...
                </span>
                <span class="Anchor">Read more</span>
              </div>
              <div class="home-info-container4">
                <span class="Card-Text">12 Apr 2021</span>
                <div class="home-stats-container4">
                  <div class="home-messages-container4">
                    <svg viewBox="0 0 1024 1024" class="home-icon56">
                      <path
                        d="M938.667 490.539v-21.205c0-0.725-0.043-1.621-0.085-2.475-5.803-99.755-47.488-190.336-112.768-258.176-68.352-71.125-162.731-117.419-268.843-123.264-0.64-0.043-1.493-0.085-2.304-0.085h-20.864c-59.947-0.683-122.965 13.227-181.931 43.008-52.181 26.539-97.749 63.531-133.931 108.203-56.405 69.675-89.899 158.037-89.941 253.653-0.597 54.4 10.795 111.36 35.157 165.419l-75.605 226.859c-2.816 8.363-3.072 17.835 0 26.965 7.467 22.357 31.616 34.432 53.973 26.965l226.731-75.563c49.493 22.485 105.984 35.243 165.376 35.115 58.539-0.384 115.84-13.141 168.149-36.949 81.579-37.163 151.040-101.248 193.707-186.667 27.477-53.291 43.307-115.84 43.136-181.803zM853.333 490.795c0.128 52.267-12.459 101.333-33.664 142.464-34.176 68.352-88.832 118.784-153.259 148.139-41.387 18.859-86.869 28.971-133.376 29.312-52.096 0.128-101.163-12.459-142.293-33.664-10.624-5.504-22.528-6.059-33.067-2.56l-162.261 54.101 54.101-162.261c3.755-11.221 2.56-22.912-2.389-32.725-23.552-46.72-34.304-96.213-33.792-142.464 0.043-76.331 26.411-145.877 70.912-200.917 28.629-35.328 64.768-64.725 106.283-85.76 46.592-23.552 96.085-34.304 142.336-33.792h19.456c83.712 4.565 158.037 41.003 212.011 97.109 51.285 53.376 84.139 124.416 89.003 202.837z"
                      ></path>
                    </svg>
                    <span class="Card-Text">123</span>
                  </div>
                  <div class="home-views-container4">
                    <svg viewBox="0 0 1024 1024" class="home-icon58">
                      <path
                        d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"
                      ></path>
                    </svg>
                    <span class="Card-Text">4567</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="home-card4">
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                class="home-image6"
              />
              <div class="home-content-container6">
                <span class="home-text43 SmallCard-Heading">
                  This text is the name of the article. Lorem ipsum dolor sit metsed
                  ...
                </span>
                <span class="Anchor">Read more</span>
              </div>
              <div class="home-info-container5">
                <span class="Card-Text">12 Apr 2021</span>
                <div class="home-stats-container5">
                  <div class="home-messages-container5">
                    <svg viewBox="0 0 1024 1024" class="home-icon60">
                      <path
                        d="M938.667 490.539v-21.205c0-0.725-0.043-1.621-0.085-2.475-5.803-99.755-47.488-190.336-112.768-258.176-68.352-71.125-162.731-117.419-268.843-123.264-0.64-0.043-1.493-0.085-2.304-0.085h-20.864c-59.947-0.683-122.965 13.227-181.931 43.008-52.181 26.539-97.749 63.531-133.931 108.203-56.405 69.675-89.899 158.037-89.941 253.653-0.597 54.4 10.795 111.36 35.157 165.419l-75.605 226.859c-2.816 8.363-3.072 17.835 0 26.965 7.467 22.357 31.616 34.432 53.973 26.965l226.731-75.563c49.493 22.485 105.984 35.243 165.376 35.115 58.539-0.384 115.84-13.141 168.149-36.949 81.579-37.163 151.040-101.248 193.707-186.667 27.477-53.291 43.307-115.84 43.136-181.803zM853.333 490.795c0.128 52.267-12.459 101.333-33.664 142.464-34.176 68.352-88.832 118.784-153.259 148.139-41.387 18.859-86.869 28.971-133.376 29.312-52.096 0.128-101.163-12.459-142.293-33.664-10.624-5.504-22.528-6.059-33.067-2.56l-162.261 54.101 54.101-162.261c3.755-11.221 2.56-22.912-2.389-32.725-23.552-46.72-34.304-96.213-33.792-142.464 0.043-76.331 26.411-145.877 70.912-200.917 28.629-35.328 64.768-64.725 106.283-85.76 46.592-23.552 96.085-34.304 142.336-33.792h19.456c83.712 4.565 158.037 41.003 212.011 97.109 51.285 53.376 84.139 124.416 89.003 202.837z"
                      ></path>
                    </svg>
                    <span class="Card-Text">123</span>
                  </div>
                  <div class="home-views-container5">
                    <svg viewBox="0 0 1024 1024" class="home-icon62">
                      <path
                        d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"
                      ></path>
                    </svg>
                    <span class="Card-Text">4567</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </template>
  
  <script>

  export default {
    name: 'AppAbout',
    props: {},
    data() {
      return {
        rawd9wf: ' ',
        raww0ed: ' ',
        rawhjnr: ' ',
      }
    },
    components: {
  },
    metaInfo: {
      title: 'About Us Page',
      meta: [
        {
          property: 'og:title',
          content: 'About Us Page',
        },
      ],
    },
  }
  </script>
  
  <style scoped>
  @media (max-width: 768px) {
    .home-container1{
      padding-top: 150px;
      height: 100vh;
    }
    .home-hero{
      background-size: cover;
      justify-content: center;
      background-image: url("/assets/bg.png") !important;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

/* ✅ iPhone-Specific Adjustments */
@media (max-width: 430px) and (max-height: 932px) { /* Covers all iPhones */
  .home-container1{
    padding-top: 150px;
    background-image: url("/assets/bg.png") !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}
  .home-container1 {
    width: 100%;
    height: auto;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .home-navbar-container {
    top: 0;
    width: 100%;
    display: flex;
    z-index: 100;
    position: sticky;
    box-shadow: 5px 5px 10px 0px #b9b9b9;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-navbar {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    align-items: center;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
  }
  .home-left-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-image1 {
    width: 50px;
    align-self: center;
    object-fit: cover;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-burger-menu {
    display: none;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
  }
  .home-icon10 {
    width: 36px;
    height: 36px;
  }
  .home-links-container1 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-link10 {
    margin-right: var(--dl-space-space-tripleunit);
    text-decoration: none;
  }
  .home-link11 {
    margin-right: var(--dl-space-space-tripleunit);
    text-decoration: none;
  }
  .home-link12 {
    margin-right: var(--dl-space-space-tripleunit);
    text-decoration: none;
  }
  .home-link13 {
    text-decoration: none;
  }
  .home-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-cta-btn1 {
    color: var(--dl-color-gray-white);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-fiveunits);
    border-radius: var(--dl-radius-radius-radius24);
    padding-right: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
  }
  .home-mobile-menu {
    top: 0px;
    flex: 0 0 auto;
    left: 0px;
    width: 100%;
    height: 100VH;
    display: none;
    padding: var(--dl-space-space-doubleunit);
    position: absolute;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .home-container2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: row;
    justify-content: space-between;
  }
  .home-image2 {
    width: 50px;
    align-self: center;
    object-fit: cover;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-close-menu {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-icon12 {
    width: 24px;
    height: 24px;
  }
  .home-links-container2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-link14 {
    margin-bottom: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .home-link15 {
    margin-bottom: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .home-link16 {
    margin-bottom: var(--dl-space-space-unit);
    text-decoration: none;
  }
  .home-link17 {
    text-decoration: none;
  }
  .home-hero {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tenunits);
    background-size: cover;
    justify-content: center;
    background-image: url("/assets/bg-about.png");
    background-repeat: no-repeat;
    background-position: center;
  }
 
  .home-content-container1 {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-tenunits);
    justify-content: center;
  }
  .home-hero-text {
    width: 65%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-heading10 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text10 {
    color: var(--dl-color-gray-white);
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-cta-btn2 {
    color: var(--dl-color-gray-white);
    text-align: center;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tenunits);
    border-radius: 30px;
    padding-right: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-doubleunit);
    background-color: var(--dl-color-gray-black);
  }
  .home-features {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    background-image: url("/assets/bg.png");
  }
  .home-heading-container1 {
    width: 45%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-text11 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-cards-container1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-features-card1 {
    width: 30%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-icon-container1 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon14 {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  .home-text-container1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-heading11 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text12 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-features-card2 {
    width: 30%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-icon-container2 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon17 {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  .home-text-container2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-heading12 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text13 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-features-card3 {
    width: 30%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-icon-container3 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon20 {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  .home-text-container3 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-heading13 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text14 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-features-card4 {
    width: 30%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-icon-container4 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon23 {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  .home-text-container4 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-heading14 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text15 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-features-card5 {
    width: 30%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-icon-container5 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon26 {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  .home-text-container5 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-heading15 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text16 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-features-card6 {
    width: 30%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-icon-container6 {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon29 {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  .home-text-container6 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-heading16 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text17 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-services {
    /* width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    background-image: url("/assets/bg.png"); */
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tenunits);
    background-size: cover;
    justify-content: center;
    background-image: url("/assets/bg-about.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-heading-container2 {
    width: 45%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-text18 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text19 {
    color: var(--dl-color-gray-white);
    text-align: center;
  }
  .home-cards-container2 {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-service-card1 {
    width: 45%;
    height: 400px;
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: #0e0a3d;
  }
  .home-text20 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text21 {
    color: var(--dl-color-gray-white);
    text-align: left;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-service-card2 {
    width: 45%;
    height: 400px;
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: #0e0a3d;
  }
  .home-text22 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text23 {
    color: var(--dl-color-gray-white);
    text-align: left;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-service-card3 {
    width: 30%;
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-900);
  }
  .home-text24 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text25 {
    color: var(--dl-color-gray-white);
    text-align: left;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-section-separator1 {
    width: 100%;
    height: 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-team {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    background-image: url("/assets/bg.png");
  }
  .home-heading-container3 {
    width: 45%;
    display: flex;
    align-items: center;
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-text26 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text27 {
    color: var(--dl-color-gray-white);
    text-align: center;
  }
  .home-cards-container3 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-team-card1 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
  }
  .home-avatar-container1 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    margin-bottom: var(--dl-space-space-tripleunit);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon32 {
    width: 48px;
    height: 48px;
  }
  .home-name1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-position1 {
    color: var(--dl-color-gray-white);
  }
  .home-team-card2 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
  }
  .home-avatar-container2 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    margin-bottom: var(--dl-space-space-tripleunit);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon34 {
    width: 48px;
    height: 48px;
  }
  .home-name2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-position2 {
    color: var(--dl-color-gray-white);
  }
  .home-team-card3 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
  }
  .home-avatar-container3 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    margin-bottom: var(--dl-space-space-tripleunit);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon36 {
    width: 48px;
    height: 48px;
  }
  .home-name3 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-position3 {
    color: var(--dl-color-gray-white);
  }
  .home-team-card4 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
  }
  .home-avatar-container4 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    margin-bottom: var(--dl-space-space-tripleunit);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon38 {
    width: 48px;
    height: 48px;
  }
  .home-name4 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-position4 {
    color: var(--dl-color-gray-white);
  }
  .home-team-card5 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
  }
  .home-avatar-container5 {
    display: flex;
    padding: var(--dl-space-space-fourunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    margin-bottom: var(--dl-space-space-tripleunit);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-icon40 {
    width: 48px;
    height: 48px;
  }
  .home-name5 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-position5 {
    color: var(--dl-color-gray-white);
  }
  .home-section-separator2 {
    width: 100%;
    height: 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-our-story {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-heading-container4 {
    width: 45%;
    display: flex;
    align-items: center;
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-text28 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text29 {
    color: var(--dl-color-gray-white);
    text-align: center;
  }
  .home-cards-container4 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-left-section {
    width: 50%;
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    margin-right: var(--dl-space-space-fourunits);
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
  }
  .home-video-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-gray-900);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-video {
    width: 100%;
    height: 350px;
    position: relative;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-play-container {
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    right: auto;
    width: 50px;
    bottom: auto;
    height: 50px;
    display: flex;
    position: absolute;
    align-items: center;
    border-color: var(--dl-color-gray-500);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    justify-content: center;
  }
  .home-icon42 {
    fill: var(--dl-color-gray-500);
    width: 48px;
    height: 48px;
  }
  .home-content-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: flex-start;
  }
  .home-heading17 {
    text-align: left;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text30 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text31 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-info-container1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container1 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container1 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon44 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container1 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon46 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-right-section {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
  }
  .home-card1 {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-image3 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container3 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-text34 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container2 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container2 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon48 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container2 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon50 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-card2 {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-image4 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container4 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-text37 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container3 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container3 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon52 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container3 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon54 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-card3 {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-image5 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container5 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-text40 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container4 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container4 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container4 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon56 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container4 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon58 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-card4 {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-image6 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container6 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-text43 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container5 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container5 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container5 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon60 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container5 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon62 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-section-separator3 {
    width: 100%;
    height: 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-get-in-touch {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: flex-start;
  }
  .home-text46 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-content-container7 {
    width: 100%;
    display: flex;
    align-self: stretch;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-form-container {
    width: 40%;
    display: flex;
    align-self: stretch;
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    margin-right: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-tenunits);
    border-radius: var(--dl-radius-radius-radius8);
    padding-right: var(--dl-space-space-tenunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    background-color: var(--dl-color-gray-900);
  }
  .home-heading18 {
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-name6 {
    width: 100%;
    border: none;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-email1 {
    width: 100%;
    border: none;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-message {
    width: 100%;
    border: none;
    height: 140px;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-cta-btn3 {
    color: var(--dl-color-gray-white);
    text-align: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-tenunits);
    border-radius: var(--dl-radius-radius-radius24);
    padding-right: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
  }
  .home-locations-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-location1 {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-heading19 {
    font-size: 24px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-adress {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: row;
  }
  .home-icon64 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-email2 {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: row;
  }
  .home-icon66 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-phone {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: row;
  }
  .home-icon68 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-section-separator4 {
    width: 100%;
    height: 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .home-footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-footer {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    align-items: center;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-social-links {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-icon70 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-icon72 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-icon74 {
    width: 24px;
    height: 24px;
  }
  .home-copyright-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon76 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  @media(max-width: 991px) {
    .home-hero {
      flex-direction: column-reverse;
    }
    .home-content-container1 {
      flex-direction: column-reverse;
    }
    .home-hero-text {
      width: 80%;
    }
    .home-cards-container3 {
      justify-content: center;
    }
    .home-cards-container4 {
      flex-direction: column;
    }
    .home-left-section {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-right-section {
      width: 100%;
    }
    .home-form-container {
      width: 60%;
    }
    .home-locations-container {
      flex-wrap: wrap;
    }
    .home-location1 {
      margin-bottom: var(--dl-space-space-tripleunit);
    }
  }
  @media(max-width: 767px) {
    .home-burger-menu {
      display: flex;
      padding: var(--dl-space-space-halfunit);
      background-color: var(--dl-color-gray-black);
    }
    .home-icon10 {
      fill: var(--dl-color-gray-white);
    }
    .home-links-container1 {
      display: none;
    }
    .home-heading-container1 {
      width: 100%;
    }
    .home-cards-container1 {
      align-items: center;
      flex-direction: column;
    }
    .home-features-card1 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card2 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card3 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card4 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card5 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card6 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-heading-container2 {
      width: 100%;
    }
    .home-cards-container2 {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }
    .home-service-card1 {
      width: 65%;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-service-card2 {
      width: 65%;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-service-card3 {
      width: 65%;
    }
    .home-heading-container3 {
      width: 100%;
    }
    .home-heading-container4 {
      width: 100%;
    }
    .home-cards-container4 {
      align-items: center;
      flex-direction: column;
    }
    .home-content-container7 {
      width: 100%;
      flex-direction: column;
    }
    .home-form-container {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-locations-container {
      flex-direction: row;
      justify-content: center;
    }
  }
  @media(max-width: 479px) {
    .home-cta-btn1 {
      font-size: 16px;
      padding-left: var(--dl-space-space-doubleunit);
      padding-right: var(--dl-space-space-doubleunit);
    }
    .home-hero {
      padding-top: var(--dl-space-space-fourunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-content-container1 {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-hero-text {
      width: 100%;
    }
    .home-features {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-features-card1 {
      width: 100%;
    }
    .home-features-card2 {
      width: 100%;
    }
    .home-features-card3 {
      width: 100%;
    }
    .home-features-card4 {
      width: 100%;
    }
    .home-features-card5 {
      width: 100%;
    }
    .home-features-card6 {
      width: 100%;
    }
    .home-services {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-service-card1 {
      width: 100%;
    }
    .home-service-card2 {
      width: 100%;
    }
    .home-service-card3 {
      width: 100%;
    }
    .home-team {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-text26 {
      text-align: center;
    }
    .home-our-story {
      padding-top: var(--dl-space-space-fiveunits);
    }
    .home-text28 {
      text-align: center;
    }
    .home-video {
      height: 200px;
    }
    .home-card1 {
      width: 100%;
    }
    .home-card2 {
      width: 100%;
    }
    .home-card3 {
      width: 100%;
    }
    .home-card4 {
      width: 100%;
    }
    .home-content-container7 {
      padding-left: 0px;
    }
    .home-form-container {
      padding: var(--dl-space-space-tripleunit);
      margin-right: 0px;
    }
    .home-heading18 {
      text-align: center;
    }
    .home-location1 {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
  </style>
  