<template>
  <div class="hero17-header78">
    <div >
      <div>
        <!-- <slot>
          <img
            :alt="hero1Alt"
            :src="hero1Src"
          />
        </slot> -->

        <div class="video-container">
          <video ref="videoPlayer" autoplay loop muted playsinline class="video">
            <source src="/assets/hero-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

      </div>

      
     
    </div>



    <!-- <div class="col-12" style="display: flex; justify-content: space-between; margin: 10px;"> 
      <div class="col-6" style="margin: 0 20px;">
        <img class="hero17-placeholder-image17" :alt="image1Alt" :src="image1Src"/>
      </div>
      <div class="col-6">
        <img class="hero17-placeholder-image17" :alt="image2Alt" :src="image2Src"/>
      </div>
    </div>
    <div class="col-12" style="display: flex; justify-content: space-between; margin: 10px;"> 
      <div class="col-6" style="margin: 0 20px;">
        <img class="hero17-placeholder-image17" :alt="image3Alt" :src="image3Src"/>
      </div>
      <div class="col-6">
        <img class="hero17-placeholder-image17" :alt="image4Alt" :src="image4Src"/>
      </div>
    </div> -->

    <!-- <div class="hero17-content2"> -->
<!--players-->
      <!-- <div
        class="hero17-row-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal"
      >
        <div class="thq-animated-group-horizontal-reverse">
          <img
            :alt="image5Alt"
            :src="image5Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image6Alt"
            :src="image6Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image7Alt"
            :src="image7Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image8Alt"
            :src="image8Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image9Alt"
            :src="image9Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image10Alt"
            :src="image10Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image11Alt"
            :src="image11Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image12Alt"
            :src="image12Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image13Alt"
            :src="image13Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image14Alt"
            :src="image14Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image15Alt"
            :src="image15Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
        </div>
        <div class="thq-animated-group-horizontal-reverse">
          <img
            :alt="image5Alt"
            :src="image5Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image6Alt"
            :src="image6Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image7Alt"
            :src="image7Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image8Alt"
            :src="image8Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image9Alt"
            :src="image9Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image10Alt"
            :src="image10Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image11Alt"
            :src="image11Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image12Alt"
            :src="image12Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image13Alt"
            :src="image13Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image14Alt"
            :src="image14Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image15Alt"
            :src="image15Src"
            class="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
          />
        </div>
      </div> -->

<!--brands-->
      <!-- <div
        class="hero17-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal"
      >
        <div class="thq-animated-group-horizontal">
          <img
            :alt="image16Alt"
            :src="image16Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image17Alt"
            :src="image17Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image18Alt"
            :src="image18Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image19Alt"
            :src="image19Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image20Alt"
            :src="image20Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
         
        </div>

        <div class="thq-animated-group-horizontal">
          <img
            :alt="image16Alt"
            :src="image16Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image17Alt"
            :src="image17Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image18Alt"
            :src="image18Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image19Alt"
            :src="image19Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
          <img
            :alt="image20Alt"
            :src="image20Src"
            class="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
          />
         
        </div>
      </div> -->
    <!-- </div> -->
    <!-- <div>
      <div class="hero17-container2">
        <DangerousHTML
          html="<style>
    @keyframes scroll-x {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(-100% - 16px));
      }
    }
  
    @keyframes scroll-y {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(calc(-100% - 16px));
      }
    }
  </style>
  "
        ></DangerousHTML>
      </div>
    </div> -->
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

export default {
  name: 'Hero17',
  props: {
    hero1Src: {
      type: String,
      default:
     '/assets/hero-video.svg',
    },
    hero1Alt: {
      type: String,
      default: 'sport',
    },
    image1Src: {
      type: String,
      default:
      '/assets/sports/tennis.png',
    },
    image1Alt: {
      type: String,
      default: 'sport',
    },
    image2Src: {
      type: String,
      default:
    '/assets/sports/padel.png',
    },
    image2Alt: {
      type: String,
      default: 'sport',
    },
    image3Src: {
      type: String,
      default:
       '/assets/sports/pickleball.png',
    },
    image3Alt: {
      type: String,
      default: 'sport',
    },
    
    image4Src: {
      type: String,
      default:
     '/assets/sports/badminton.png',
    },
    image4Alt: {
      type: String,
      default: 'sport',
    },



    image5Alt: {
      type: String,
      default: 'Players',
    },
    image5Src: {
      type: String,
      default:
      '/assets/players/JANNIK.png',
    },
    image6Src: {
      type: String,
      default:
      '/assets/players/ZVEREV.png',
    },
    image6Alt: {
      type: String,
      default: 'Players',
    },
    image7Src: {
      type: String,
      default:
      '/assets/players/NOVAK.png',
    },
    image7Alt: {
      type: String,
      default: 'Players',
    },
    image8Src: {
      type: String,
      default:
      '/assets/players/NADAL.png',
    },
    image8Alt: {
      type: String,
      default: 'Players',
    },
    image9Src: {
      type: String,
      default:
      '/assets/players/ALCARAZ.png',
    },
    image9Alt: {
      type: String,
      default: 'Players',
    },
    image10Src: {
      type: String,
      default:
      '/assets/players/NINO.png',
    },
    image10Alt: {
      type: String,
      default: 'Players',
    },
    image11Src: {
      type: String,
      default:
      '/assets/players/ALEX.png',
    },
    image11Alt: {
      type: String,
      default: 'Players',
    },
    image12Alt: {
      type: String,
      default: 'Players',
    },
    image12Src: {
      type: String,
      default:
      '/assets/players/COCO.png',
    },
    image13Alt: {
      type: String,
      default: 'Players',
    },
    image13Src: {
      type: String,
      default:
      '/assets/players/MOCHOVA.png',
    },
    image14Alt: {
      type: String,
      default: 'Players',
    },
    image14Src: {
      type: String,
      default:
      '/assets/players/THIEM.png',
    },
    image15Alt: {
      type: String,
      default: 'Players',
    },
    image15Src: {
      type: String,
      default:
      '/assets/players/FRITZ.png',
    },
    image16Alt: {
      type: String,
      default: 'brands',
    },
    image16Src: {
      type: String,
      default:
      '/assets/brands/head.png',
    },
    image17Alt: {
      type: String,
      default: 'brands',
    },
    image17Src: {
      type: String,
      default:
      '/assets/brands/babolat.png',
    },
    image18Alt: {
      type: String,
      default: 'brands',
    },
    image18Src: {
      type: String,
      default:
      '/assets/brands/dunlop.png',
    },
    image19Alt: {
      type: String,
      default: 'brands',
    },
    image19Src: {
      type: String,
      default:
      '/assets/brands/JOOLA.png',
    },
    image20Alt: {
      type: String,
      default: 'brands',
    },
    image20Src: {
      type: String,
      default:
      '/assets/brands/tecniq.png',
    },

    
  },
  methods: {
    playVideo() {
      this.$refs.videoPlayer.play();
    },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
    }
  },
  components: {
    DangerousHTML,
  },
}
</script>

<style scoped>
.video-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black; /* Prevents white space on load */
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* ✅ Fix for iPhones (Avoid black bars & cropping) */
@media (max-width: 768px) {
  .video-container {
  width: 100% !important;
  height: 100% !important;
 
}
  video {
    width: auto;
    height: 100%;
    max-width: 100%;
  }
}

/* ✅ iPhone-Specific Adjustments */
@media (max-width: 430px) and (max-height: 932px) { /* Covers all iPhones */
  .video-container {
  width: 100% !important;
  height: 100% !important;
 
}
  video {
    width: 100%;
    height: auto;
  }
}
/* Ensures video fits properly on all devices */
@media (max-aspect-ratio: 16/9) {
  .video-container {
  width: 100% !important;
  height: 100% !important;
 
}
  .video {
    width: auto;
    height: 100%;
  }
}

@media (min-aspect-ratio: 16/9) {
  .video-container {
  width: 100% !important;
  height: 100% !important;
 
}
  .video {
    width: 100%;
    height: auto;
  }
}
.hero17-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero17-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.hero17-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero17-text1 {
  text-align: center;
}
.hero17-text2 {
  text-align: center;
}
.hero17-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}
.hero17-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.hero17-row-container1 {
  
  width: 100%;
}
.hero17-placeholder-image10 {
  width: 800px;
  height: 400px;
}
.hero17-placeholder-image11 {
  width: 500px;
  height: 400px;
}

.hero17-row-container2 {
  width: 100%;
}
.hero17-placeholder-image22 {
  width: 400px;
  height: 400px;
}

.hero17-container2 {
  display: contents;
}
.hero17-fragment1 {
  display: contents;
}
.hero17-text5 {
  display: inline-block;
}
.hero17-fragment2 {
  display: contents;
}
.hero17-text6 {
  display: inline-block;
}
.hero17-fragment3 {
  display: contents;
}
.hero17-text7 {
  display: inline-block;
}
.hero17-fragment4 {
  display: contents;
}
.hero17-text8 {
  display: inline-block;
  font-family: 'Semibold' !important;
}
@media(max-width: 767px) {
  .hero17-content2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .hero17-actions {
    width: 100%;
    flex-direction: column;
  }
  .hero17-button1 {
    width: 100%;
  }
  .hero17-button2 {
    width: 100%;
  }
}
</style>
