import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/home'
import About from './views/about-us'
import CoachAi from './components/news/coachAi'
import Head from './components/news/head.vue'
import Juniors from './components/news/juniorsProgram.vue'
import News from './views/news'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    // {
    //   name: 'About',
    //   path: '/about-us',
    //   component: About,
    // },
    // {
    //   name: 'News',
    //   path: '/',
    //   component: News,
    // },
    {
      name: 'CoachAi',
      path: '/racketnation-sponsors-coach-Ai',
      component: CoachAi,
    },
    {
      name: 'Head',
      path: '/racketnation-and-head',
      component: Head,
    },
    {
      name: 'Juniors',
      path: '/racketnation-juniors-program',
      component: Juniors,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
})
